import React, { useRef, useEffect, useContext, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { AppContext } from "../context/AppContext";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import findMiddleGround from "../utils/findMiddleGround";
import getCategories from "../utils/getCategories";
import chevron from "../assets/dropdown-chevron.svg";
import filterLocations from "../utils/filterLocations";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

function Controller() {
  const {
    address1,
    setAddress1,
    address2,
    setAddress2,
    middleGround,
    setMiddleGround,
    filters,
    setFilters,
    locations,
    resultNum,
    setResultNum
  } = useContext(AppContext);
  const [categories, setCategories] = useState([]);

  const resultOptions = [5,10,15,20];

  const geocoder1 = useRef(null);
  const geocoder2 = useRef(null);

  // Store references to the event listener functions
  let geocoder1Listener;
  let geocoder2Listener;

  useEffect(() => {
    // Initialize geocoder for Address 1
    if (geocoder1.current && geocoder1.current.onRemove) {
      geocoder1.current.onRemove();
    }
    if (geocoder2.current && geocoder2.current.onRemove) {
      geocoder2.current.onRemove();
    }

    geocoder1.current = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: "Enter Address 1",
      types: "address",
      proximity: {
        longitude: -118.2437,
        latitude: 34.0522,
      },
      // proximity: false, // Remove this line or replace with valid coordinates
    });

    // Initialize geocoder for Address 2
    geocoder2.current = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: "Enter Address 2",
      types: "address",
      proximity: {
        longitude: -118.2437,
        latitude: 34.0522,
      },
      // proximity: false, // Remove this line or replace with valid coordinates
    });

    // Attach geocoders to inputs
    if (geocoder1.current) {
      document
        .getElementById("geocoder1")
        .appendChild(geocoder1.current.onAdd(null));
    }

    if (geocoder2.current) {
      document
        .getElementById("geocoder2")
        .appendChild(geocoder2.current.onAdd(null));
    }

    // Define the listener for geocoder1
    geocoder1Listener = (e) => {
      setAddress1(e.result);
      console.log(e.result);
    };

    // Define the listener for geocoder2
    geocoder2Listener = (e) => {
      setAddress2(e.result);
    };

    // Attach the listeners to the geocoders
    geocoder1.current.on("result", geocoder1Listener);
    geocoder2.current.on("result", geocoder2Listener);

    return () => {
      // Clean up geocoder inputs on unmount
      if (geocoder1.current && geocoder1Listener) {
        geocoder1.current.off("result", geocoder1Listener);
      }
      if (geocoder2.current && geocoder2Listener) {
        geocoder2.current.off("result", geocoder2Listener);
      }
    };
  }, [setAddress1, setAddress2]);

  useEffect(() => {
    if (locations.length > 0) {
      const cats = getCategories(locations);
      setCategories(cats);
    }
  }, [locations]);

  const getMiddleGround = () => {
    if (!address1 || !address2) {
      return
    }

    if (middleGround.length === 0) {
      let locs;
      if (filters.length > 0) {
        locs = filterLocations(locations, filters);
      } else {
        locs = [...locations];
      }
      const mg = findMiddleGround(address1, address2, locs, resultNum);
      setMiddleGround([...mg]);
    } else {
      setMiddleGround([]);
      setFilters([]);
      // setAddress1("");
      geocoder1.current.setInput("");
      // setAddress2("");
      geocoder2.current.setInput("");
    }
  };

  const handleFilters = (e) => {
    setFilters(e.target.value)
  }

  return (
    <div className="meeting-point">
      <div className="input-container" id="geocoder1"></div>{" "}
      {/* Geocoder for Address 1 */}
      <div className="input-container" id="geocoder2"></div>{" "}
      {/* Geocoder for Address 2 */}
      <div className="button" onClick={getMiddleGround}>
        {middleGround.length > 0 ? "CLEAR" : "FIND MIDDLE GROUND"}
      </div>
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="demo-multiple-name-label">FILTERS</InputLabel>
        <Select
          multiple
          value={filters}
          onChange={handleFilters}
          variant="standard"
          disableUnderline={true}
          renderValue={(selected) => (
            <div style={{ width: '100%', textAlign: 'center', fontFamily: '"Bitter", system-ui' }}>
              {selected}
            </div>
          )}
          sx={{
            // Additional styles if needed
            '& .MuiSelect-select': {
              textAlign: 'center',
              fontFamily: '"Bitter", system-ui'
            },
          }}
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
              sx={{
                fontFamily: '"Bitter", system-ui'
              }}
            >
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="result-number"># OF RESULTS</InputLabel>
        <Select
          value={resultNum}
          onChange={(e) => setResultNum(e.target.value)}
          variant="standard"
          disableUnderline={true}
          renderValue={(selected) => (
            <div style={{ width: '100%', textAlign: 'center',fontFamily: '"Bitter", system-ui' }}>
              {selected}
            </div>
          )}
          sx={{
            // Additional styles if needed
            '& .MuiSelect-select': {
              textAlign: 'center',
              fontFamily: '"Bitter", system-ui'
            },
          }}
        >
          {resultOptions.map((r,i) => {
            return (
              <MenuItem
              key={`resultNum-${i}`}
              value={r}
              sx={{
                fontFamily: '"Bitter", system-ui'
              }}
            >
              {r}
            </MenuItem>
            )
          })}
          
        
        </Select>
      </FormControl>
    </div>
  );
}

export default Controller;

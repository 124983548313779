export default function getCategories(locations) {
    const categories = new Set(); // Using a Set to store unique values
  
    locations.forEach((doc) => {
      if (doc.properties && doc.properties.category) {
        categories.add(doc.properties.category); // Add the category to the Set
      }
    });
  
    // Convert the Set to an array and sort alphabetically
    return Array.from(categories).sort((a, b) => a.localeCompare(b));
  }
import React, { useRef, useEffect, useState, useContext } from "react";
import mapboxgl from "mapbox-gl";
import { AppContext } from "../context/AppContext";
import { BounceLoader } from "react-spinners"; // Optional spinner
import filterLocations from "../utils/filterLocations";
import findMiddleGround from "../utils/findMiddleGround";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const tnftIcon = "/assets/tnft_logo.png";

function Map() {
  const {
    address1,
    address2,
    middleGround,
    setMiddleGround,
    filters,
    locations,
    setLocations,
    resultNum,
  } = useContext(AppContext);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-118.243683);
  const [lat, setLat] = useState(34.052235);
  const [zoom, setZoom] = useState(10);
  const [loading, setLoading] = useState(true); // Loading state

  const initialCenter = [lng, lat];
  const initialZoom = zoom;

  useEffect(() => {
    if (map.current) return; // Initialize map only once

    renderAllPoints();
  }, []);

  const renderAllPoints = () => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("load", () => {
      console.log("map is loaded");

      // Ensure the custom icon is added only once
      if (!map.current.hasImage("custom-icon")) {
        map.current.loadImage(tnftIcon, (error, image) => {
          if (error) return;
          map.current.addImage("custom-icon", image);
          loadGeoJsonData(); // Only load GeoJSON after the image is added
        });
      } else {
        loadGeoJsonData(); // If the image is already added, just load the data
      }
    });
  };

  const loadGeoJsonData = () => {
    // Fetch GeoJSON data
    fetch("https://82sdqbf61k.execute-api.us-west-1.amazonaws.com/locations")
      .then((response) => response.json())
      .then((data) => {
        // Add the GeoJSON source
        if (!map.current.getSource("featureData")) {
          map.current.addSource("featureData", {
            type: "geojson",
            cluster: false,
            data: data.data,
          });

          // Add a symbol layer using the custom icon
          map.current.addLayer({
            id: "points",
            type: "symbol",
            source: "featureData",
            layout: {
              "icon-image": "custom-icon", // Use the custom icon
              "icon-size": 0.15, // Adjust size if needed
              "icon-allow-overlap": true,
            },
          });
          setLocations(data.data.features);
        }
        map.current.on("click", "points", (e) => {
          // Get the coordinates and properties of the clicked point
          const coordinates = e.features[0].geometry.coordinates.slice();
          const properties = e.features[0].properties;

          // Ensure the popup is shown at the correct position (prevent map shift)
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Create a popup and set its HTML content from the point's properties
          const popup = new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(
              `
                      <h3 style="font-family: Bitter, system-ui;">${properties.Locations}</h3>
                      <p style="font-family: Bitter, system-ui;">${properties.Address}</p>
                      <p style="font-family: Bitter, system-ui;">Featured in: ${properties.Name}</p>
                      <a style="font-family: Bitter, system-ui;" href="https://thenextfunthing.com/la-guide/${properties.slug}" target="_blank" rel="noopener noreferrer">Link to full guide</a>
                      `
            )
            .addTo(map.current);

          map.current.flyTo({
            center: coordinates, // The coordinates of the clicked point
            zoom: 14, // Set the desired zoom level
            essential: true, // Ensures the transition is smooth
          });

          popup.on("close", () => {
            map.current.flyTo({
              center: initialCenter, // Return to the initial center
              zoom: initialZoom, // Return to the initial zoom level
              essential: true, // Smooth transition
            });
          });
        });

        // Change the cursor to pointer when hovering over points
        map.current.on("mouseenter", "points", () => {
          map.current.getCanvas().style.cursor = "pointer";
        });

        // Change the cursor back when not hovering over points
        map.current.on("mouseleave", "points", () => {
          map.current.getCanvas().style.cursor = "";
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading GeoJSON:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!resultNum) return;
    if (middleGround.length > 0) {
      let locs;
      if (filters.length > 0) {
        locs = filterLocations(locations, filters);
      } else {
        locs = [...locations];
      }
      const mg = findMiddleGround(address1, address2, locs, resultNum);
      setMiddleGround([...mg]);
    }
  }, [resultNum]);

  useEffect(() => {
    const clearExistingPoints = () => {
      // If you previously added a layer or source, remove them
      if (map.current.getLayer("points")) {
        map.current.removeLayer("points");
      }
      if (map.current.getSource("points")) {
        map.current.removeSource("points");
      }

      // Remove any markers (if added using Marker class)
      if (map.current.markers) {
        map.current.markers.forEach((marker) => marker.remove());
      }
    };

    if (middleGround.length > 0) {
      clearExistingPoints();
      middleGround.forEach((location) => {
        const [lon, lat] = location.geometry.coordinates;

        const el = document.createElement("div");
        el.className = "custom-marker";
        el.style.backgroundImage = `url(${tnftIcon})`;
        el.style.width = "30px"; // Adjust width as per your PNG size
        el.style.height = "30px"; // Adjust height as per your PNG size
        el.style.backgroundSize = "100%";
        const popupContent = `
        <h3 style="font-family: Bitter, system-ui;">${location.properties.Locations}</h3>
        <p style="font-family: Bitter, system-ui;">${location.properties.Address}</p>
        <p style="font-family: Bitter, system-ui;">Featured in: ${location.properties.Name}</p>
        <a style="font-family: Bitter, system-ui;" href="https://thenextfunthing.com/la-guide/${location.properties.slug}" target="_blank" rel="noopener noreferrer">Link to full guide</a>
        `;
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);

        // Create a marker for each location
        const marker = new mapboxgl.Marker(el)
          .setLngLat([lon, lat])
          .setPopup(popup)
          .addTo(map.current);

        // Optional: Store the markers to remove them later
        if (!map.current.markers) map.current.markers = [];
        map.current.markers.push(marker);
      });

      const bounds = new mapboxgl.LngLatBounds();
      middleGround.forEach((location) => {
        const [lon, lat] = location.geometry.coordinates;
        bounds.extend([lon, lat]);
      });
      map.current.fitBounds(bounds, { padding: 50 });
    } else if (middleGround.length === 0) {
      renderAllPoints();
    }
  }, [middleGround]);

  useEffect(() => {
    const clearExistingPoints = () => {
      // If you previously added a layer or source, remove them
      if (map.current.getLayer("points")) {
        map.current.removeLayer("points");
      }
      if (map.current.getSource("points")) {
        map.current.removeSource("points");
      }

      // Remove any markers (if added using Marker class)
      if (map.current.markers) {
        map.current.markers.forEach((marker) => marker.remove());
      }
    };

    if (filters.length > 0) {
      clearExistingPoints();

      const filtered = filterLocations(locations, filters);
      let locs;
      if (middleGround.length > 0) {
        locs = findMiddleGround(address1, address2, filtered, resultNum);
        setMiddleGround([...locs]);
      } else {
        locs = [...filtered];
      }

      locs.forEach((location) => {
        const [lon, lat] = location.geometry.coordinates;

        const el = document.createElement("div");
        el.className = "custom-marker";
        el.style.backgroundImage = `url(${tnftIcon})`;
        el.style.width = "30px"; // Adjust width as per your PNG size
        el.style.height = "30px"; // Adjust height as per your PNG size
        el.style.backgroundSize = "100%";
        const popupContent = `
        <h3 style="font-family: Bitter, system-ui;">${location.properties.Locations}</h3>
        <p style="font-family: Bitter, system-ui;">${location.properties.Address}</p>
        <p style="font-family: Bitter, system-ui;">Featured in: ${location.properties.Name}</p>
        <a style="font-family: Bitter, system-ui;" href="https://thenextfunthing.com/la-guide/${location.properties.slug}" target="_blank" rel="noopener noreferrer">Link to full guide</a>
        `;
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);

        // Create a marker for each location
        const marker = new mapboxgl.Marker(el)
          .setLngLat([lon, lat])
          .setPopup(popup)
          .addTo(map.current);

        // Optional: Store the markers to remove them later
        if (!map.current.markers) map.current.markers = [];
        map.current.markers.push(marker);
      });

      const bounds = new mapboxgl.LngLatBounds();
      filtered.forEach((location) => {
        const [lon, lat] = location.geometry.coordinates;
        bounds.extend([lon, lat]);
      });
      map.current.fitBounds(bounds, { padding: 50 });
    } else if (filters.length === 0) {
      renderAllPoints();
    }
  }, [filters]);

  return (
    <div>
      {loading && ( // Show spinner while loading
        <div className="spinner-container">
          <BounceLoader color={"#123abc"} loading={loading} size={60} />
        </div>
      )}
      <div ref={mapContainer} className="map-container" />
    </div>
  );
}

export default Map;

import { createContext } from "react";

export const AppContext = createContext({
  address1: null,
  setAdress1: (event) => {},
  address2: null,
  setAdress2: (event) => {},
  middleGround: [],
  setMiddleGround: (event) => {},
  filters: [],
  setFilters: (event) => {},
  locations: [],
  setLocations: (event) => {},
  resultNum: null,
  setResultNum: (event) => {}
});

import React, { useRef, useEffect, useState } from "react";
import { AppContext } from "./context/AppContext";

import Controller from "./components/Controller";
import Map from "./components/Map";

function App() {
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [middleGround, setMiddleGround] = useState([]);
  const [filters, setFilters] = useState([]);
  const [locations, setLocations] = useState([]);
  const [resultNum, setResultNum] = useState(5);

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          address1,
          setAddress1,
          address2,
          setAddress2,
          middleGround,
          setMiddleGround,
          locations,
          setLocations,
          filters,
          setFilters,
          resultNum,
          setResultNum
        }}
      >

        <div style={{ zIndex: 999, position: "relative" }}>
          <Controller />
        </div>

        {/* The map should be behind the controller */}
        <div style={{ zIndex: 0, position: "relative" }}>
          <Map />
        </div>
      </AppContext.Provider>
    </div>
  );
}

export default App;

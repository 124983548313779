// import locations from "../locations.json";


function getMidpoint(lat1, lon1, lat2, lon2) {
  const dLon = toRadians(lon2 - lon1);

  // Convert degrees to radians
  lat1 = toRadians(lat1);
  lat2 = toRadians(lat2);
  lon1 = toRadians(lon1);

  const Bx = Math.cos(lat2) * Math.cos(dLon);
  const By = Math.cos(lat2) * Math.sin(dLon);

  const lat3 = Math.atan2(
    Math.sin(lat1) + Math.sin(lat2),
    Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By)
  );
  const lon3 = lon1 + Math.atan2(By, Math.cos(lat1) + Bx);

  return { latitude: toDegrees(lat3), longitude: toDegrees(lon3) };
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function toDegrees(radians) {
  return radians * (180 / Math.PI);
}

function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

function findClosestLocations(midpoint, locations, numResults = 5) {
  // Create an array of locations with their distances from the midpoint
  const locationsWithDistance = locations.map((location) => {
    const [lon, lat] = location.geometry.coordinates;
    const distance = haversineDistance(
      midpoint.latitude,
      midpoint.longitude,
      lat,
      lon
    );
    return { ...location, distance }; // Return the location along with its distance
  });

  // Sort the locations by distance (ascending order)
  locationsWithDistance.sort((a, b) => a.distance - b.distance);

  // Return the top numResults closest locations (5 by default)
  return locationsWithDistance.slice(0, numResults);
}

export default function findMiddleGround(address1, address2, locations, resultNum) {
  const midpoint = getMidpoint(
    address1.center[1],
    address1.center[0],
    address2.center[1],
    address2.center[0]
  );
  const closestLocations = findClosestLocations(midpoint, locations, resultNum);
  return closestLocations;
}
